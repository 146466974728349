.nav_menu_thalamus {
    .dropdown-menu {
        margin-left: -100px;
        min-width: 180px;
    }

    ul {
        box-sizing: content-box;
        margin: 5px;
        padding: 0;
        display: block;
        list-style-type: disc;
    }

    li {
        display: inline-block;
        list-style-type: none;
        padding: 6px;
        position: relative;
        vertical-align: top;
        height: 84px;
        width: 84px;
    }

    .app_link {
        border-radius: 8px;
        margin: 0;
        outline: none;
        text-align: center;
        text-decoration: none;
        width: 84px;
    }

    .icon {
        background-image: url(imgs/ticons.png);
        background-size: 53px;
        display: inline-block;
        height: 53px;
        vertical-align: top;
        width: 53px;
    }
    
    .iam {
        background-position: 0px -5px;
    }
    
    .register {
        background-position: 0px -57px;
    }
}